import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import { Container, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player'
// Images
import mobVideoBannerImg from "../../../images/home/mob-video-banner.jpg"
import tabVideoBannerImg from "../../../images/home/tab-video-banner.jpg"
import videoBannerImg from "../../../images/home/video-banner-img.jpg"
// styles
import "./MarketVideo.scss"
// markup
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";

import { youtube_parser } from "../../common/utils";

import $ from "jquery";

import { ImageModule, SourceBlock } from '../../../modules/Image_Module';

import FullScreenVideo from '../../../modules/full_screen_video';



const MarketVideo = (props) => {
  const [isPlay, setPlay] = useState(false);

  const [isPlaying,setPlaying] = useState(false);

  const [show, setShow] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const [controls, setVideoControls] = useState(false);
  const [mute, setMute] = useState(true);
  const vidRef = useRef(null);
  const pausevideo = (ref) => {
      setPlayvideo(false)
      setVideoControls(false)

  }
  
  const showControls = () => {
      setVideoControls(true)
  }

  const playvideo = (ref) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'formClick',
        'formType': 'video-container',
        'formId': 'video-container',
        'formName': "Video",
        'formLabel': window.location.href
    });


    setPlayvideo(true)
    setVideoControls(false)
  }
  const Show_Video = (boolean) => {
    setShow(boolean)
  }

  const Show_HTML_Video = (boolean) => {
    setHTMLVideo(boolean)
  }


  var youtube_url = props.Embed_Video_URL && youtube_parser(props.Embed_Video_URL);
  var video_url = "https://www.youtube-nocookie.com/embed/"+youtube_url

  //console.log("youtube_url",youtube_url);

  // const playvideo = () => {
  //   $("#study-banner-wrp").hide();
  //   $("#video").show();
  // }

  //console.log("MarketVideo", props.Background_Image)

  return (
    <div className="market-video">

        {showHTMLVideo &&
            <FullScreenVideo ref={vidRef} url={video_url} show={show} mute={mute} play={play} Show_HTML_Video={Show_HTML_Video}  Show_Video={Show_Video} />
        }

      
      {/*  <div className="animated"className="video_inner" animateIn='fadeIn' > */}
      <div className="video_inner" >
        {
          isPlay ? (
            <>
              <a href="javascript:;" className="close_video" onClick={() => setPlay(false)}>X</a>

              <ReactPlayer url={props.Embed_Video_URL} playing={true} controls={true} width="100%" height="870px" className="video" />
            </>
          ) : (
            <>
              <picture>
                <ImageModule classNames={"bg-img"} ImageSrc={props.Background_Image} />
              </picture>
              {props?.Embed_Video_URL && 
                <strong to="#" className="play-btn" onClick={() => { setPlay(true); playvideo(); setShow(true); }}>
                  <span className="btn-anim d-flex align-items-center justify-content-center">
                    <span className="text-center">
                      <i className="play-icon"></i>
                      {/* <strong className="">Play</strong> */}
                      <strong className="d-md-block">Play Movie</strong>
                    </span>
                  </span>
                </strong>
              }
            </>
          )
        }
      </div>
      {/*  </div> */}

      {/* <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId={youtube_url}
              isAutoPlay={1}
        /> */}
    </div>
  )
}

export default MarketVideo