import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Button, Container, Form, Nav, Tab } from "react-bootstrap"
import bgCurve2 from "../../images/home/pg-logo-curve-2.png"

import {externalURL} from "../common/utils"

import $ from "jquery"

import "./BottomBlock.scss"

const BottomBlock = (props) => {

  const scrollDiv = (div_id) => {
    $('html, body').animate({ scrollTop:$(div_id).offset().top - 100}, 3000);
  }

  return (  
    <div className={`bottom-block ${props.Bottom_Block?.Module_Class}`}>
      <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>
      <Container>
        {/*  <div className="animated"> */}
          <div className="tab-details">
              <h2 className="tab-title">
                {props.Bottom_Block.Bottom_Title}
              </h2>
              <p className="tab-desc">
                {props.Bottom_Block.Bottom_Content}
              </p>
              {
                props.Bottom_Block.CTA_1_URL === "#enquiry_form" ? 

                  props.Bottom_Block.CTA_1_Label && <a href="javascript:;" onClick={()=>scrollDiv(props.Bottom_Block.CTA_1_URL)} className="btn btn-primary" ><span>{props.Bottom_Block.CTA_1_Label}</span></a>
                : 
                props.Bottom_Block.CTA_1_URL === "get_free_quote" ? 
                  props.Bottom_Block.CTA_1_Label && <a href="javascript:;" onClick={(e) => props.openModalForm(e, props.Bottom_Block.CTA_1_Label, props.Bottom_Block.CTA_1_URL)} className="btn btn-primary" ><span>{props.Bottom_Block.CTA_1_Label}</span></a>
                :
                  props.Bottom_Block.CTA_1_Label && <Link to={props.Bottom_Block.CTA_1_URL} target={externalURL(props.Bottom_Block.CTA_1_URL) ? "_blank" : ""} className="btn btn-primary"><span>{props.Bottom_Block.CTA_1_Label}</span></Link>
              }
              
          </div>
        {/*  </div> */}
      </Container>
    </div>
  )
}

export default BottomBlock
