import React from "react" ;
import HTMLReactParser from "html-react-parser";
import '../IntroCopyBlock/IntroBlock.scss';
const IntroBlock = (props) => {
    return(
       
        <div className="intro-block-section">
          <h2 className="intro-block-title">{props?.IntroTitle}</h2>
          <div className="intro-block-content">{props?.IntroPara && HTMLReactParser(props?.IntroPara)}</div>
        </div>
    )
}
export default IntroBlock;