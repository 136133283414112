import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import { Button, Container, Form, Nav, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
// Images
import reviewImg from "../../../images/home/property-rating-img.png"
import bgCurve from "../../../images/home/pg-logo-curve.png"
// styles
import disImg1 from "../../../images/home/discover-img01.jpg"
import disImg2 from "../../../images/home/discover-img02.jpg"
import disImg3 from "../../../images/home/discover-img03.jpg"
import ContactCard from "../ContactCard/ContactCard"
import "./ProjectRating.scss"
// markup
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollContainer from 'react-indiana-drag-scroll'

import { addCommas, stb_sale_link, stb_rent_link, stb_commercial_link } from "../../common/utils"

import axios from "axios"


const ProjectRating = (props) => {

    //console.log("props_title", props.Title)

    const [propItems, setPropItems] = useState([]);

    var stb_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/";

    const getitems = async url => {
        setPropItems([]);
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // 
            // console.log("PropertySimilar", data)
            setPropItems(data)
            //return data;
        } catch (error) {
            // console.error(error)
        }
    }

    // useEffect(() => {
    //     //
    //     let property_sale = getitems(stb_sale_link);
    // }, []);

    useEffect(() => {
        //
        props.StbList && props.StbList.length > 0 ?
          getitems(stb_link+props.StbList[0].Stb_List_Item)
        : getitems(stb_sale_link);
    }, [props.StbList]);

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1199,
                settings: "unslick",
            },
            {
                breakpoint: 99999,
                settings: "unslick",
            },
        ]
    }

    //console.log("propItems", propItems && propItems);

    return (
        <div className="market-analysis project-analysis">
            <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
            <Container>
                {/*  <div className="animated"> */}
                <div className="discover-range d-md-block">
                    {
                        props.Heading ? (
                            <h2 className="text-center">{props.Heading}</h2>
                        ) : (
                            <h2 className="text-center">{props.Title}</h2>
                        )
                    }

                    <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                        <Nav variant="pills">
                            <div className="pills-wrap">
                                {/*  <div className="animated"> */}
                                <ScrollContainer className="scroll-container">
                                    {
                                        props.StbList && props.StbList.length > 0 ?
                                            props.StbList.map((item, index) =>{
                                                var tab_name = item.Stb_List_Item.replace(/-/g, ' ');

                                                return(
                                                    item.Stb_List_Item === "sold-properties" ? (
                                                        <Nav.Item>
                                                            <Nav.Link eventKey={index} onClick={() => getitems(stb_link+item.Stb_List_Item+"?pid=60f925fa30f8f877f44a9c58")}>{item.Stb_List_Title}</Nav.Link>
                                                        </Nav.Item>
                                                    ) : (
                                                        <Nav.Item>
                                                            <Nav.Link eventKey={index} onClick={() => getitems(stb_link+item.Stb_List_Item)}>{item.Stb_List_Title}</Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                    
                                                )
                                                
                                            }) : (
                                            <>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="0" onClick={() => getitems(stb_sale_link)}>Properties for Sale</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1" onClick={() => getitems(stb_rent_link)}>Properties for Rent</Nav.Link>
                                                </Nav.Item>
                                                {
                                                    props.CommunityTab !== false &&
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="2" onClick={() => getitems(stb_commercial_link)}>Commercial Property</Nav.Link>
                                                    </Nav.Item>
                                                }
                                            </>
                                        )
                                    }
                                   </ScrollContainer>
                                {/*  </div> */}
                            </div>
                        </Nav>
                        {/*  <div className="animated"> */}
                            <Tab.Content>
                                <Tab.Pane eventKey="0" className="text-center">
                                    {
                                        propItems && propItems.length > 0 && (
                                            <Slider {...settings} >
                                                {
                                                    propItems.map((item, index) => {

                                                        var url = "/properties-for-sale/";
                                                        var title = item.title;

                                                        if(item.status === "for sale"){
                                                            url = "/properties-for-sale/"
                                                        } else if(item.status === "rented"){
                                                            url = "/properties-let/"
                                                            title = title.replace('for rent', 'let')
                                                        } else if(item.status === "for rent"){
                                                            url = "/properties-for-rent/"
                                                        } else if(item.status === "sold"){
                                                            url = "/properties-sold/"
                                                            title = title.replace('for sale', 'sold')
                                                        }

                                                        return (
                                                            <ContactCard
                                                                cardImg={item.images.length > 0 && item.images[0]}
                                                                cardTitle={title+' in '+item.display_address}
                                                                date={`${addCommas(item.price)} QR`}
                                                                cardTag={""}
                                                                cardUrl={`${url}${item.slug}-${item.id}`}
                                                                article_id={item.id}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        )
                                    }
                                    
                                </Tab.Pane>

                                <Tab.Pane eventKey="1" className="text-center">
                                    {
                                        propItems && propItems.length > 0 && (
                                            <Slider {...settings} >
                                                {
                                                    propItems.map((item, index) => {

                                                        var url = "/properties-for-sale/";

                                                        if(item.status === "for sale"){
                                                            url = "/properties-for-sale/"
                                                        } else if(item.status === "rented"){
                                                            url = "/properties-let/"
                                                        } else if(item.status === "for rent"){
                                                            url = "/properties-for-rent/"
                                                        } else if(item.status === "sold"){
                                                            url = "/properties-sold/"
                                                        }
                                                        return (
                                                            <ContactCard
                                                                cardImg={item.images.length > 0 && item.images[0]}
                                                                cardTitle={item.title+' in '+item.display_address}
                                                                date={`${addCommas(item.price)} QR`}
                                                                cardTag={""}
                                                                cardUrl={`${url}${item.slug}-${item.id}`}
                                                                article_id={item.id}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        ) 
                                    }
                                    
                                </Tab.Pane>

                                {
                                    props.CommunityTab !== false &&
                                    <Tab.Pane eventKey="2" className="text-center">
                                        {
                                            propItems && propItems.length > 0 && (
                                                <Slider {...settings} >
                                                    {
                                                        propItems.map((item, index) => {
                                                            if (item.status === "for sale") {
                                                                var commercial_link = "commercial-properties-for-sale";
                                                            } else {
                                                                var commercial_link = "commercial-properties-for-rent";
                                                            }
                                                            return (
                                                                <ContactCard
                                                                    cardImg={item.images.length > 0 && item.images[0]}
                                                                    cardTitle={item.title+' in '+item.display_address}
                                                                    date={`${addCommas(item.price)} QR`}
                                                                    cardTag={""}
                                                                    cardUrl={`/${commercial_link}/${item.slug}-${item.id}`}
                                                                    article_id={item.id}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            )
                                        }
                                        
                                    </Tab.Pane>
                                }
                            </Tab.Content>
                        {/*  </div> */}
                    </Tab.Container>
                </div>
                {/*  </div> */}
            </Container>
        </div>
    )
}

export default ProjectRating