import { Link } from "@StarberryUtils";
import React,{useEffect, useState} from "react"
import {Container, Row, Col,Badge} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLParser from 'html-react-parser';

// Images
import Img1Large from "../../images/static2/img1-desktop.jpg"
import Img1Medium from "../../images/static2/img1-tablet.jpg"
import Img1Small from "../../images/static2/img1-mobile.jpg"
import Img2Large from "../../images/static2/img2-desktop.jpg"
import Img2Medium from "../../images/static2/img2-tablet.jpg"
import Img2Small from "../../images/static2/img2-mobile.jpg"


// Style
import  './Communities.scss';

import {ImageModule} from "../../modules/Image_Module";
import {CommunityList} from "../../queries/common_use_query"

const Communities = ( props ) => {

  const {loading:loading, error:error, data:data} = CommunityList();

  return(
    <div className="benefit-block">
      <Container>
        {

          data && data.communities.length > 0 && data.communities.map((item, index) => {

            let processedImages = JSON.stringify({});
            if (item.imagetransforms && item.imagetransforms.Tile_Image_Transforms) {
                processedImages = item.imagetransforms.Tile_Image_Transforms;
            }

            return(
              <Row className='community_list'>
                <Col lg="5" className='community_padd align-self-start'>
                 <div className="animated">
                  <div className="benefit-block-img img-zoom">
                    <picture>
                      {/* <source media="(min-width:992px)" srcset={Img1Large} />
                      <source media="(min-width:768px)" srcset={Img1Medium} />
                      <img className="" src={Img1Small} alt="" /> */}
                      {item.Tile_Image && item.Tile_Image.url && <ImageModule ImageSrc={item.Tile_Image} imagename={"communities.Tile_Image.tile_image"} article_id={item.id} imagetransforms={processedImages} /> }
                    </picture>
                  </div>
                 </div>
                </Col>
                <Col lg="7" className='community_padd'>
                 <div className="animated">
                  <div className="benefit-block-content ">
                    <h2>{item.Name}, {item.Location}</h2>
                    {item.Content && HTMLParser(item.Content)}

                    <Link to={item.URL} className="btn btn-primary">
                        <span>Find out more</span>
                    </Link>
                  </div>
                 </div>
                </Col>
              </Row>
            )
          })          
        }

      </Container>
    </div>  
  )
}

export default Communities