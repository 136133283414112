import React, { useState, useEffect } from "react"

// export const TableuIframe = (props) => {
    
//     let viz;
//     const ref = React.useRef(null);
//     const url = props.graphUrl;
//     const[iframe_scroll, setScroll] = useState(false);
//     const [iframeWidth, setIframeWidth] = useState(900);
//     const [iframeHeight, setIframeHeight] = useState(700);

//     useEffect(()=>{
//         typeof window !== "undefined" && (window.innerWidth < 1200) && setScroll(true);
//     },[props]);

    
//     useEffect(() => {
//         const width = window.innerWidth <= 1024 ? window.innerWidth - 50 : window.innerWidth < 1200 ? 870 : 970
//         const height = window.innerWidth <= 1200 ? props.index == 1 ? 850 : 580 : 580
//         const options = {
//             width: width,
//             height: height
//         }
//         setIframeWidth(width)
//         setIframeHeight(height)
//         viz = new window.tableau.Viz(ref.current, url, options);

//         window.addEventListener("resize", () => {
//             const widthR = window.innerWidth <= 1024 ? window.innerWidth - 50 : window.innerWidth < 1200 ? 870 : 970
//             const heightR = window.innerWidth <= 1200 ? 850 : 580
//             viz.setFrameSize(widthR, heightR);
//             const sheet = viz.getWorkbook().getActiveSheet();
//             sheet.changeSizeAsync({
//                 behavior: "EXACTLY",
//                 maxSize: {
//                     width: widthR,
//                     height: heightR,
//                 },
//             }).then(viz.setFrameSize(widthR-100, heightR));
//         })
        
//     },[]);
//     return(
//         <div className="tableau_container">
//             <div className="d-lg-block d-none" ref={ref}/>
//             <iframe 
//                 marginheight="0" 
//                 marginwidth="0" 
//                 title="Data Visualization"  
//                 width={iframeWidth} 
//                 height={iframeHeight}
//                 scrolling={iframe_scroll ? 'auto' : props.overFlow} 
//                 allowtransparency="true" 
//                 allowfullscreen="true" 
//                 className="tableauViz d-lg-none d-block" 
//                 src={`${props.graphUrl}&:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fpublic.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=no&:display_static_image=no&:display_spinner=no&:display_overlay=yes&:display_count=yes&:language=en-US&:loadOrderID=0`} 
//                 frameborder="1"
//             />
//         </div>
//     )
// }
export const TableuIframe = (props) => {

    const[iframe_scroll, setScroll] = useState(false);

    useEffect(()=>{
        typeof window !== "undefined" && (window.innerWidth < 1200) && setScroll(true);
    },[props]);

    return(
        <iframe marginheight="0" marginwidth="0" title="Data Visualization"  width="100%" 
        height="100%" scrolling={iframe_scroll ? 'auto' : props.overFlow} allowtransparency="true" allowfullscreen="true" class="tableauViz" src={`${props.graphUrl}&:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fpublic.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=no&:display_static_image=no&:display_spinner=no&:display_overlay=yes&:display_count=yes&:language=en-US&:loadOrderID=0`} frameborder="1"></iframe>
    )
}
