import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Button, Container, Form, Nav, Tab } from "react-bootstrap"
import mobMarketGraph from "../../../images/home/mob-graph.jpg"
import tabMarketGraph from "../../../images/home/tab-graph.png"
import deskMarketGraph from "../../../images/home/desk-graph.png"
import "./MarketAnalysis.scss"

import Carousel from "../../Carousel"
import { v4 as uuidv4 } from "uuid"
import ScrollContainer from 'react-indiana-drag-scroll'
import { ImageModule } from '../../../modules/Image_Module';

import { TableuIframe } from '../../../modules/tableu_iframe_graph';

const MarketAnalysis = (props) => {
  var slidesArr = [];
  let [goToSlide, setGoto] = useState(0);
  let [slides, setSlides] = useState('');
  let [activeTab, setActiveTab] = useState({ tab_name: '', tab_title: '', tab_content: '', tab_more_info: '', tab_more_url: '' })
  let [offsetRadius, setOffsetRadius] = useState(50)
  let [showNavigation, setShowNavigation] = useState(true)
  let [config, setConfig] = useState("")

  React.useEffect(() => {
    if (config.gentle) setConfig(config.gentle)
  }, [config]);


  {/* ==== First Tab Slides Active ==== */ }
  useEffect(() => {
    if (props.Tab_Data && props.Tab_Data.length > 0) {
      var slide_image = props.Tab_Data[0].Graph_Image;
      // if (slide_image.length > 0) {
      //   slide_image.map((slide_data, index) => {
      //     slidesArr.push({
      //       key: uuidv4(),
      //       //content: <ImageModule classNames={"graph-img"} ImageSrc={slide_data.Graph_Image} />,
      //       content: <TableuIframe graphUrl={slide_data.Embed_Code} overFlow={props.Alias === "land-and-developments" ? "auto" : "no"}/>,
      //     });
      //   })
      // }
      // setSlides(slidesArr);
      setSlides(slide_image[0].Embed_Code);
      setActiveTab({ tab_name: props.Tab_Data[0].Tab_Name, tab_title: props.Tab_Data[0].Tab_Title, tab_content: props.Tab_Data[0].Tab_Content, tab_more_info: props.Tab_Data[0].CTA_1_Label, tab_more_url: props.Tab_Data[0].CTA_1_URL });
    }
  }, [])

  //console.log("config", config)



  {/* ==== Tab Change After Change ==== */ }
  const getSlides = (tab_data, index) => {
    // console.log("tab_data", tab_data);

    if (tab_data.Graph_Image && tab_data.Graph_Image.length > 0) {
      // tab_data.Graph_Image.map((slide_data, index) => {
      //   slidesArr.push({
      //     key: uuidv4(),
      //     content: <TableuIframe graphUrl={tab_data.Graph_Image[0].Embed_Code} overFlow={props.Alias === "land-and-developments" ? "auto" : "no"}/>,
      //   });
      // })
      setSlides(tab_data.Graph_Image[0].Embed_Code);
    }
   
    setActiveTab({ tab_name: tab_data.Tab_Name, tab_title: tab_data.Tab_Title, tab_content: tab_data.Tab_Content, tab_more_info: tab_data.CTA_1_Label, tab_more_url: props.Tab_Data[index].CTA_1_URL });
  }

  //console.log("activeTab", activeTab.tab_more_url);

  //console.log("slides===> 1", slides);
  
  return (
    <div className="market-analysis"> 
      <Container>
        <div className="mob-graph d-none d-md-none">
          <img src={mobMarketGraph} alt="graph" />
        </div>
      
        {
          activeTab.tab_name && (

            <>
              <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab.tab_name}>
                <Nav variant="pills" className="flex-column">
                  
                  <div className="pills-wrap">
                  <ScrollContainer className="scroll-container">
                    {
                      props.Tab_Data && props.Tab_Data.length > 0 && props.Tab_Data.map((tab, index) => {
                        return (
                          <Nav.Item>
                            <Nav.Link eventKey={tab.Tab_Name} onClick={() => getSlides(tab, index)}>{tab.Tab_Name}</Nav.Link>
                          </Nav.Item>
                        )
                      }
                      )
                    }
                    </ScrollContainer>
                  </div>
                  
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey={activeTab.tab_name} className="text-center">
                    <div className="graph-status d-md-block">
                      {/* <Carousel
                        slides={slides}
                        goToSlide={goToSlide}
                        offsetRadius={offsetRadius}
                        showNavigation={showNavigation}
                        animationConfig={config}
                        play={true}
                        interval={6000}
                      /> */}
                      {/* <div
                        style={{
                          margin: "0 auto",
                          marginTop: "2rem",
                          width: "50%",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      ></div> */}
                      <TableuIframe graphUrl={slides} overFlow={props.Alias === "land-and-developments" ? "auto" : "no"}/>
                    </div>

                    <div className="tab-details">
                      <h2 className="tab-title">{activeTab.tab_title}</h2>
                      <p className="tab-desc">{activeTab.tab_content}</p>

                      {activeTab.tab_more_info &&
                        <Link to={activeTab.tab_more_url} className="btn btn-primary">
                          <span>{activeTab.tab_more_info}</span>
                        </Link>
                      }

                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>

            </>
          )
        }

      </Container>
    </div>
  )
}

export default MarketAnalysis
