import React from "react";
import HTMLReactParser from 'html-react-parser';
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import ScrollAnimation from 'react-animate-on-scroll';

const TitleBlock =(props) => {

  return(
  <div className="filter-block insights full_wdth">
    <Container>
        <Row> 
        <Col xl={12} lg={12} md={12} className="full_width_filter_block">
            <div className="filter-block-title">
               <div className="animated">
                <h1>{props.Title}</h1>
                <div className="article-section">
                  <div className="article-section-content">
                    {HTMLReactParser(props.Description)}
                  </div>
                </div>
               </div>
            </div>
        </Col>
        </Row>
    </Container>
  </div>
  )
}

export default TitleBlock;