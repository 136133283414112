import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./AccordionBlock.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

import HTMLReactParser from 'html-react-parser';

import { TableuIframe } from '../../modules/tableu_iframe_graph_accordion';
import $ from "jquery"

const TableuAccordionBlock = props => {

  var split_hash_id = props.location.hash;
  var active_id = '';
  if(split_hash_id === "#lease-to-own"){
    active_id = "0";
  } else if(split_hash_id === "#fix-flip"){
    active_id = "1";
  } else if(split_hash_id === "#land-banking"){
    active_id = "2";
  }

  const [activeId, setActiveId] = useState(active_id ? active_id : props.activeId ? props.activeId : "");
  const [viewMore, setViewMore] = useState(props.viewMore);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }

    setTimeout(function(){
      $('html, body').animate({
        scrollTop: $(".accord_"+id).offset().top - 200
      }, 500);
    }, 300)
  }


  useEffect(()=>{
    if(split_hash_id){
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $(split_hash_id).offset().top - 100
        }, 500);        
      }, 1000)
      
    }
  },[split_hash_id]);

  // useEffect(()=>{
  //   setTimeout(function(){
  //     $("#acc_id_0").trigger('click');
  //   }, 3000);
  // },[])

  // console.log("$$$$", props.AccordionItems.length)

  return (
    <div className={`accordion-block`}>

      <Row>
        <Col lg={12}>

           <div className="animated">
            {
              props.AccordionItems && props.AccordionItems.length > 0 ?
              <Accordion defaultActiveKey={activeId}>
                {
                  props.AccordionItems && props.AccordionItems.length > 0 && props.AccordionItems.map((item, index) => {

                    // console.log('&&&&&', index)
                    var remove_spcl_char = item.Accordian_Heading.replace(/& /g, "");
                    var acc_id = (remove_spcl_char.trim().replace(/ /g,'-'));
                    return (
                      <Card id={acc_id.toLowerCase()} className={`accord_${index.toString()}`}>
                        <Accordion.Toggle id={`acc_id_${index.toString()}`} onClick={() => toggleActive(index.toString())} className={`accordion-header`} eventKey={index.toString()}>
                          {item.Accordian_Heading}

                          {
                            props.AccordionItems && props.AccordionItems.length > 1 && <i className={activeId === index.toString() ? "icon-minus-accordion" : "icon-plus-accordion"}></i>
                          }
                          
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={index.toString()}>
                          <div className="accordion-card">
                            {/* <p>{item.Tableu_Embed_Code && HTMLReactParser(item.Tableu_Embed_Code)}</p> */}
                      
                            {
                              (activeId === index.toString() && item.Tableu_Embed_Code) &&
                              <TableuIframe graphUrl={item.Tableu_Embed_Code} />
                            }
                            <p>{item.Accordian_Content && HTMLReactParser(item.Accordian_Content)}</p>
                          </div>
                        </Accordion.Collapse>
                      </Card>
                    )
                  })
                }
              </Accordion>
              :
              <>
                
                {/* {
                  props.AccordionItems && props.AccordionItems.length > 0 && props.AccordionItems.map((item, index) => {

                    // console.log('&&&&&', index)

                    return (
                      <Card>
                        <div className="accordion-header">
                          {item.Accordian_Heading}
                        </div>

                        <div className="accordion-card">
                            {
                              item.Tableu_Embed_Code &&
                              <TableuIframe graphUrl={item.Tableu_Embed_Code} />
                            }

                            <p>{item.Accordian_Content && HTMLReactParser(item.Accordian_Content)}</p>
                          </div>
                      </Card>
                    )
                  })
                } */}
              </>
            }
           </div>

        </Col>
      </Row>

    </div>
  )
}

export default TableuAccordionBlock
