import * as React from "react"
import { Container } from "react-bootstrap"
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import AccordionBlock from "../AccordionBlock/AccordionBlock"
import "./Content.scss"
import $ from "jquery" ;
import { useLocation } from "@reach/router";
// styles
// markup
const StepContentBlock = (props) => {
    
  let location = useLocation();
  //console.log("stepsss",props)
  const scrollSection = (id) => {
    //console.log("testtt",id)
    $("html, body").animate({
        scrollTop: $(id).offset().top - 200,
    },100)
  }
  React.useEffect(() =>{
    
    if(location.hash !== ""){
      scrollSection(location.hash)
       
    }
  })
  return (
  <React.Fragment>
      
        <div className="article-section step-content">
         <div className="animated"> 
            <div className="article-section-content">
              {props.StepContent.map((steps , k)=>{
                return(
                    <div id={steps.Slug} key={k}>
                      <h4>{steps.Title}</h4>
                   { steps.Content && HTMLReactParser(steps.Content) }
                    </div>
                )
              })}
             
            </div>
         </div>
        </div>

    </React.Fragment>
  )
}

export default StepContentBlock