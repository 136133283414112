import React from "react";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import ScrollContainer from 'react-indiana-drag-scroll'

const Breadcrumbs =(props) => {
  if (typeof window !== "undefined") {
    var lastURLSegment = '';
    var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
    if(props.alias !=null) {
      lastURLSegment = props.alias
    }
  }

  // console.log("lastURLSegment", lastURLSegment);

 const data = useStaticQuery(graphql`
    query BreadcrumbQuery {  
      glstrapi {
        allMenus{
          Primary_URL
          id
          Label
          Main_Parent {
            Primary_URL
            Label
          }
          Sub_Parent {
            Label
            Primary_URL
          }
        }
      }    
    }
  `)

  //console.log("lastURLSegment", lastURLSegment)

  return(
  <div className="breadcrumb-block breadcrumb-content text-center faq_breacrumbs" >
    <Container>
        <Row> 
        <Col lg={12} className="p-0">
            <ScrollContainer className="scroll-container">
              <Breadcrumb>
                <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
                {
                  data.glstrapi && data.glstrapi.allMenus && data.glstrapi.allMenus.map((node, key) => {

                    return(
                      <>
                        {node.Main_Parent != null && node.Primary_URL == lastURLSegment &&
                          <li className="breadcrumb-item"><Link to={`/${node.Main_Parent.Primary_URL}`}>{node.Main_Parent.Label}</Link></li>
                        }
                        {node.Main_Parent != null && node.Sub_Parent != null && node.Primary_URL == lastURLSegment &&
                          <li className="breadcrumb-item"><Link to={`/${node.Main_Parent.Primary_URL}/${node.Sub_Parent.Primary_URL}/`}>{node.Sub_Parent.Label}</Link></li>
                        }
                        {node.Main_Parent != null && node.Primary_URL == lastURLSegment && 
                          <li className="breadcrumb-item active">{node.Label}</li>
                        }
                        {(node.Main_Parent === null && node.Sub_Parent === null) && node.Primary_URL === lastURLSegment && 
                          <li className="breadcrumb-item active">{node.Label}</li>
                        } 
                      </>
                    )
                  })
                }
              </Breadcrumb>
            </ScrollContainer>
        </Col>
        </Row>
    </Container>
  </div>
  )
}

export default Breadcrumbs;

