import React, { useEffect, useState, useRef } from "react"
import { Link } from "@StarberryUtils";
import HTMLReactParser from 'html-react-parser';
import dateFormat from 'dateformat';
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import Slider from "react-slick"
import axios from "axios"
import { useMatch } from "@reach/router"
// Images
import ImgPerson from "../../images/person.jpg"
import blogImg1 from "../../images/home/blog-img-01.jpg"
import blogImg2 from "../../images/home/blog-img-02.jpg"
import blogImg3 from "../../images/home/blog-img-03.jpg"
import blogImg4 from "../../images/home/blog-img-04.jpg"
import ContactCard from "../ContactCard/ContactCard"
import {Insights, InsightsConnection} from "../../queries/common_use_query"
import "./FilterBlock.scss"
import bgCurve2 from "../../images/home/pg-logo-curve-2.png"
import { useLocation } from "@reach/router"

const FilterBlock = props => {

  const [filterCat, setFilterCat] = useState('');
  const [insightsList, setInsightsList] = useState([]);
  const [getAllInsights, setGetAllInsights] = useState([]);
  const location = useLocation();
  const data = location.state;

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        settings: "unslick",
      },
    ],
  }


  const allCategoryOption = {
    value: "all",
    label: "All"
  }
  const allCategory = [ //
    { value: "Blogs", label: "Blogs" },
    { value: "Research", label: "Research" },
    { value: "Case_Studies", label: "Case Studies" }
  ]


  const filterCateogy = (category) =>{
        //console.log("category", category);
    setFilterCat(category);
  }

  useEffect(()=>{
    var hash_name = props.location.hash ? props.location.hash.replace('#', '') : '';
    if(data !== null && data?.category === "Research"){
      setFilterCat(data.category)
    }
    hash_name && setFilterCat(hash_name);
   },[])

   useEffect(() => {
    const fetchInsights = async () => {
      try {
        const allInsights = [];
        for (let i = 0; i <= 200; i += 100) {
          const insightsFeed = await axios.get(
            `${process.env.GATSBY_STRAPI_SRC}/insights?_limit=100${filterCat ? "&Category="+filterCat : ""}&_start=${i}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
              },
            }
          );
          allInsights.push(insightsFeed.data);
        }
        setInsightsList(allInsights);
        // Merge arrays after fetching and updating insightsList state
        const merged = allInsights.reduce((acc, curr) => acc.concat(curr), []);
        merged.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        // Set the merged array as a state variable
        setGetAllInsights(merged);
      } catch (error) {
        console.error('Error:', error);
        // Handle error
      }
    };
    fetchInsights();
  }, [filterCat]);

  const { loading: initLoading, error: initError, data:initInsightsData } = Insights(filterCat ,10);
  // const {loading, error, data: insightsData} = Insights(filterCat , 200);
  const {loading:tab_loading, error:tab_error, data:tab_data} = InsightsConnection();



  var uniqueCat = [];
  console.log("tab_data=>",tab_data);
  tab_data && tab_data.insightsConnection  && tab_data.insightsConnection?.groupBy?.Category?.length > 0 && tab_data.insightsConnection?.groupBy?.Category.map((item, index) => {
    // if (uniqueCat.indexOf(item.key) === -1) {
    //   console.log("uniqueCat=>item=>",item);
    //   if(item.key){
    //     uniqueCat.push(item.key)
    //   }
    // }
    if(item.key){
      uniqueCat = allCategory.map(data => data?.value);
    }
  });


  useEffect(()=>{
    // if(insightsData){
    //   setInsightsList(insightsData?.insights)
    // }else if
    if(initInsightsData){
      setInsightsList(initInsightsData?.insights)
    }
    
   
  },[initInsightsData]) //insightsData

 //console.log("insightsList",insightsList)


  //console.log("insight_data", data);
  //console.log("uniqueCat", uniqueCat);

  var lat = '', lng = '';

  //console.log("location", props.location);
  


  return (
    <div className="filter-block insights video_filter">
      <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>
      <Container>
        <Row>
          <Col lg="12">
            {
              uniqueCat && uniqueCat.length > 0 && 
              <div className="filter-block-group">
                 <div className="animated">
                  <div className="filter-link">
                    <span className={`filter-link-btn ${!filterCat && 'filter-link-btn--active'}`} onClick={()=>setFilterCat('')}>All</span>
                    {
                      uniqueCat.map((item, index) => {
                        return(
                          <span className={`filter-link-btn ${item === filterCat && 'filter-link-btn--active'}`} onClick={()=>filterCateogy(item)}>{item.replace('_', ' ')}</span>
                        )
                      })
                    }
                  </div>
                 </div>
              </div>
            }
            
            
             <div className="animated">
            <div className="filter-block-list case_insights text-center text-xl-left">
              
                {getAllInsights && getAllInsights.length > 0 ?
                <div>
                  {getAllInsights?.map((item, index) => {

                  var date_of_item =item.Date && dateFormat(item.Date, "dd mmmm yyyy");

                  var split_tags = item.Tag ? item.Tag.split(', ') : '';

                  return(
                    <>
                      <ContactCard
                        cardImg={item?.Tile_Image}
                        cardTitle={item?.Title}
                        date={date_of_item}
                        cardTag={item?.Tag ? split_tags[0] : item?.Category?.replace('_', ' ')}
                        link_url={item?.URL}
                        page="insights"
                        article_id={item?.id}
                        imagetransforms={item?.imagetransforms}
                        externalLink={item?.External_Link}
                      />
                    </>
                  )

                  // if(filterCat === item.Category){
                  //   return(
                  //     <ContactCard
                  //       cardImg={item.Tile_Image}
                  //       cardTitle={item.Title}
                  //       date={date_of_item}
                  //       cardTag={item.Category}
                  //       link_url={item.URL}
                  //       page="insights"
                  //       imagename={"insights.Tile_Image.tile_image"}
                  //       article_id={item.id}
                  //       imagetransforms={item.imagetransforms}
                  //     />
                  //   )
                  // } else{
                  //   return(
                  //     <ContactCard
                  //       cardImg={item.Tile_Image}
                  //       cardTitle={item.Title}
                  //       date={date_of_item}
                  //       cardTag={item.Category}
                  //       link_url={item.URL}
                  //       page="insights"
                  //       imagename={"insights.Tile_Image.tile_image"}
                  //       article_id={item.id}
                  //       imagetransforms={item.imagetransforms}
                  //     />
                  //   )
                  // }

                  })
                  }
                </div>
                :
                <div>
                  {insightsList &&  insightsList?.length > 0 && insightsList?.map((item, index) => {

                  var date_of_item =item.Date && dateFormat(item.Date, "dd mmmm yyyy");

                  var split_tags = item.Tag ? item.Tag.split(', ') : '';

                  return(
                    <>
                      <ContactCard
                        cardImg={item?.Tile_Image}
                        cardTitle={item?.Title}
                        date={date_of_item}
                        cardTag={item?.Tag ? split_tags[0] : item?.Category?.replace('_', ' ')}
                        link_url={item?.URL}
                        page="insights"
                        article_id={item?.id}
                        imagetransforms={item?.imagetransforms}
                        externalLink={item?.External_Link}
                      />
                    </>
                  )

                  // if(filterCat === item.Category){
                  //   return(
                  //     <ContactCard
                  //       cardImg={item.Tile_Image}
                  //       cardTitle={item.Title}
                  //       date={date_of_item}
                  //       cardTag={item.Category}
                  //       link_url={item.URL}
                  //       page="insights"
                  //       imagename={"insights.Tile_Image.tile_image"}
                  //       article_id={item.id}
                  //       imagetransforms={item.imagetransforms}
                  //     />
                  //   )
                  // } else{
                  //   return(
                  //     <ContactCard
                  //       cardImg={item.Tile_Image}
                  //       cardTitle={item.Title}
                  //       date={date_of_item}
                  //       cardTag={item.Category}
                  //       link_url={item.URL}
                  //       page="insights"
                  //       imagename={"insights.Tile_Image.tile_image"}
                  //       article_id={item.id}
                  //       imagetransforms={item.imagetransforms}
                  //     />
                  //   )
                  // }

                  })
                  }
                </div>
                }
            </div>
             </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FilterBlock
